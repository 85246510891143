import React, { useEffect, useId, useRef, useState } from 'react';
import { ucFirst } from '../../utils/caseconverters';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import LineChart from '../LineChart';
import styles from './SavingsCalculator.module.scss';

const SavingsCalculator = ({ title = '', disclaimer = '', link = {} }) => {
    const { t } = useTranslation();

    const START_AMOUNT_VALUE = 100000;
    const START_AMOUNT_MIN_VALUE = 0;
    const START_AMOUNT_MAX_VALUE = 1000000;
    const START_AMOUNT_STEP_VALUE = 100;

    const MONTHLY_SAVING_VALUE = 2000;
    const MONTHLY_SAVING_MIN_VALUE = 0;
    const MONTHLY_SAVING_MAX_VALUE = 5000;
    const MONTHLY_SAVING_STEP_VALUE = 100;

    const YEARS_SAVING_VALUE = 20;
    const YEARS_SAVING_MIN_VALUE = 1;
    const YEARS_SAVING_MAX_VALUE = 30;
    const YEARS_SAVING_STEP_VALUE = 1;

    const INTEREST_RATE_VALUE = 8;
    const INTEREST_RATE_MIN_VALUE = 0;
    const INTEREST_RATE_MAX_VALUE = 30;
    const INTEREST_RATE_STEP_VALUE = 1;

    const BANK_FEE = 0.014;
    const BANK_RATE = 0.021;
    const BANK_DISCOUNT = 0;

    const LEVLER_FEE = 0.014;
    const LEVLER_DISCOUNT = 0.25;

    const SAVINGS_ACCOUNT_RATE_IN_PERCENTAGE = 2.1;
    const SAVINGS_ACCOUNT_FEE = 0;
    const SAVINGS_ACCOUNT_DISCOUNT = 0;

    const COLOR_GREY_80 = '#555555';
    const COLOR_GREY_75 = '#B5B5B5';
    const COLOR_VIOLET = '#a47df7';

    const [startAmount, setStartAmount] = useState(START_AMOUNT_VALUE);
    const [monthlySaving, setMonthlySaving] = useState(MONTHLY_SAVING_VALUE);
    const [yearsOfSaving, setYearsOfSaving] = useState(YEARS_SAVING_VALUE);
    const [interestRate, setInterestRate] = useState(INTEREST_RATE_VALUE);
    const [mounted, setMounted] = useState(false);

    const [series, setSeries] = useState([]);
    const [totals, setTotals] = useState({
        levler: 0,
        bank: 0,
        savingsAccount: 0,
    });

    const hasLink = !!link.href || !!link.title;

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        const levler = calculateSavings(
            interestRate,
            LEVLER_FEE,
            LEVLER_DISCOUNT
        );
        const bank = calculateSavings(interestRate, BANK_FEE);
        const savingsAccount = calculateSavingsAccountSavings(
            SAVINGS_ACCOUNT_RATE_IN_PERCENTAGE
        );

        setTotals({
            levler: levler.total,
            bank: bank.total,
            savingsAccount: savingsAccount.total,
        });

        setSeries([
            {
                name: 'Levler',
                color: COLOR_VIOLET,
                data: levler.series,
            },
            {
                name: t('SavingsCalculator.bank'),
                color: COLOR_GREY_80,
                data: bank.series,
            },
            {
                name: t('SavingsCalculator.savingsAccount'),
                color: COLOR_GREY_75,
                data: savingsAccount.series,
            },
        ]);
    }, [startAmount, monthlySaving, yearsOfSaving, interestRate]);

    const calculateSavings = (rate, managementFee, discount = 0) => {
        const INTEREST_RATE_IN_DECIMAL = rate / 100;
        const MONTHS_IN_A_YEAR = 12;

        const yearlySavingAmounts = [];
        let totalSavingsAmount = startAmount;

        for (let year = 1; year <= yearsOfSaving; year++) {
            const halfAnnualSavings = (monthlySaving * MONTHS_IN_A_YEAR) / 2;
            const feeAdjustment = managementFee * discount;

            totalSavingsAmount =
                (totalSavingsAmount + halfAnnualSavings) *
                    (1 + INTEREST_RATE_IN_DECIMAL + feeAdjustment) +
                halfAnnualSavings;

            yearlySavingAmounts.push(Math.round(totalSavingsAmount));
        }

        return {
            total: totalSavingsAmount,
            series: yearlySavingAmounts,
        };
    };

    const calculateSavingsAccountSavings = (rate) => {
        const INTEREST_RATE_IN_DECIMAL = rate / 100;
        const MONTHS_IN_A_YEAR = 12;

        const yearlySavingAmounts = [];
        let totalSavingsAmount = startAmount;

        for (let year = 1; year <= yearsOfSaving; year++) {
            if (year === 1) {
                const halfAnnualSavings =
                    (monthlySaving * MONTHS_IN_A_YEAR) / 2;
                totalSavingsAmount =
                    (totalSavingsAmount + halfAnnualSavings) *
                        (1 + INTEREST_RATE_IN_DECIMAL) +
                    halfAnnualSavings;
            } else {
                totalSavingsAmount =
                    (totalSavingsAmount + monthlySaving * MONTHS_IN_A_YEAR) *
                    (1 + INTEREST_RATE_IN_DECIMAL);
            }

            yearlySavingAmounts.push(Math.round(totalSavingsAmount));
        }

        return {
            total: totalSavingsAmount,
            series: yearlySavingAmounts,
        };
    };

    const formatNumber = (number, suffix = 'kr') => {
        number = Math.round(parseFloat(number));
        return number.toLocaleString().replace(/,/g, ' ') + ' ' + suffix;
    };

    const handleOnInput = (setState, event, minValue, maxValue) => {
        const value = parseFloat(event.target.value);

        if (isNaN(value) || value === '') {
            setState(1);
            return;
        }

        if (value < minValue) {
            setState(minValue);
            return;
        }

        if (value > maxValue) {
            setState(maxValue);
            return;
        }

        setState(value);
    };

    const sliderItems = [
        {
            title: t('SavingsCalculator.startingAmount'),
            value: startAmount,
            suffix: 'kr',
            setState: setStartAmount,
            minValue: START_AMOUNT_MIN_VALUE,
            maxValue: START_AMOUNT_MAX_VALUE,
            formatNumber: formatNumber,
            step: START_AMOUNT_STEP_VALUE,
            onInput: (event) =>
                handleOnInput(
                    setStartAmount,
                    event,
                    START_AMOUNT_MIN_VALUE,
                    START_AMOUNT_MAX_VALUE
                ),
        },
        {
            title: t('SavingsCalculator.monthlySavings'),
            value: monthlySaving,
            suffix: 'kr',
            onInput: (event) =>
                handleOnInput(
                    setMonthlySaving,
                    event,
                    MONTHLY_SAVING_MIN_VALUE,
                    MONTHLY_SAVING_MAX_VALUE
                ),
            formatNumber: formatNumber,
            minValue: MONTHLY_SAVING_MIN_VALUE,
            maxValue: MONTHLY_SAVING_MAX_VALUE,
            step: MONTHLY_SAVING_STEP_VALUE,
        },
        {
            title: t('SavingsCalculator.time'),
            value: yearsOfSaving,
            suffix: 'år',
            onInput: (event) =>
                handleOnInput(
                    setYearsOfSaving,
                    event,
                    YEARS_SAVING_MIN_VALUE,
                    YEARS_SAVING_MAX_VALUE
                ),
            formatNumber: formatNumber,
            minValue: YEARS_SAVING_MIN_VALUE,
            maxValue: YEARS_SAVING_MAX_VALUE,
            step: YEARS_SAVING_STEP_VALUE,
        },
        {
            title: t('SavingsCalculator.profit'),
            value: interestRate,
            suffix: '%',
            onInput: (event) =>
                handleOnInput(
                    setInterestRate,
                    event,
                    INTEREST_RATE_MIN_VALUE,
                    INTEREST_RATE_MAX_VALUE
                ),
            formatNumber: formatNumber,
            minValue: INTEREST_RATE_MIN_VALUE,
            maxValue: INTEREST_RATE_MAX_VALUE,
            step: INTEREST_RATE_STEP_VALUE,
        },
    ];

    return (
        mounted && (
            <div className={styles['SavingsCalculator']}>
                <div className={styles['SavingsCalculator__Container']}>
                    <h2 className={styles['SavingsCalculator__Title']}>
                        {title}
                    </h2>
                    <LineChart
                        yearsOfSaving={yearsOfSaving}
                        startAmount={startAmount}
                        monthlySaving={monthlySaving}
                        interestRate={interestRate}
                        series={series}
                    />
                    <div className={styles['SavingsCalculator__Comparison']}>
                        <ComparisonItem
                            title={t('SavingsCalculator.levler')}
                            amount={formatNumber(totals.levler)}
                            text={t('SavingsCalculator.discount')}
                            color={'violet'}
                        />
                        <ComparisonItem
                            title={t('SavingsCalculator.bank')}
                            amount={formatNumber(totals.bank)}
                            text={t('SavingsCalculator.noDiscount')}
                            color={'grey80'}
                        />
                        <ComparisonItem
                            title={t('SavingsCalculator.savingsAccount')}
                            amount={formatNumber(totals.savingsAccount)}
                            text={
                                SAVINGS_ACCOUNT_RATE_IN_PERCENTAGE.toString().replace(
                                    '.',
                                    ','
                                ) +
                                '%' +
                                ' ' +
                                t('SavingsCalculator.interest')
                            }
                            color={'grey75'}
                        />
                    </div>
                    {sliderItems.map((item, index) => (
                        <Slider key={index} {...item} />
                    ))}

                    <div className={styles['SavingsCalculator__Bottom']}>
                        <div
                            className={styles['SavingsCalculator__BottomGrid']}>
                            <span
                                className={
                                    styles['SavingsCalculator__LargeAmount']
                                }>
                                {formatNumber(totals.levler, 'kr*')}
                            </span>
                            <p
                                className={
                                    styles['SavingsCalculator__LargeText']
                                }>
                                +
                                {formatNumber(totals.levler - totals.bank) +
                                    ' '}
                                <span>
                                    {t('SavingsCalculator.comparedText')}
                                </span>
                            </p>
                        </div>
                        <div
                            className={styles['SavingsCalculator__BottomGrid']}>
                            {hasLink && <Link {...link} />}
                            {!!disclaimer && (
                                <div
                                    className={
                                        styles['SavingsCalculator__Disclaimer']
                                    }
                                    dangerouslySetInnerHTML={{ __html: disclaimer }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

const ComparisonItem = ({
    title = '',
    amount = '',
    text = '',
    color = 'violet',
}) => {
    const classes = classNames(
        styles['SavingsCalculator__ItemTitle'],
        styles['SavingsCalculator__ItemTitle--' + ucFirst(color)]
    );

    return (
        <div className={styles['SavingsCalculator__Item']}>
            <span className={classes}>{title}</span>
            <span className={styles['SavingsCalculator__Amount']}>
                {amount}
            </span>
            <span className={styles['SavingsCalculator__Text']}>{text}</span>
        </div>
    );
};

const Slider = ({
    title = '',
    value = 0,
    suffix = '',
    minValue = 0,
    maxValue = 0,
    step = 0,
    onInput,
    formatNumber,
}) => {
    const rangeId = useId();
    const inputId = useId();
    const inputRef = useRef();

    const [showInputField, setShowInputField] = useState(false);

    useEffect(() => {
        if (showInputField && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showInputField]);

    const backgroundSize = `${
        ((value - minValue) / (maxValue - minValue)) * 100
    }% 100%`;

    return (
        <div className={styles['SavingsCalculator__Slider']}>
            <div className={styles['SavingsCalculator__SliderContent']}>
                <div className={styles['SavingsCalculator__SliderTitle']}>
                    {title}
                </div>
                <div className={styles['SavingsCalculator__SliderValue']}>
                    {showInputField ? (
                        <>
                            <label className={'sr-only'} htmlFor={inputId}>
                                {title}
                            </label>
                            <input
                                className={
                                    styles['SavingsCalculator__InputField']
                                }
                                ref={inputRef}
                                id={inputId}
                                name={title}
                                type="number"
                                onInput={onInput}
                                onBlur={() => setShowInputField(false)}
                                min={minValue}
                                max={maxValue}
                                value={value}
                            />
                            <span
                                className={styles['SavingsCalculator__Suffix']}>
                                {suffix}
                            </span>
                        </>
                    ) : (
                        <button
                            className={
                                styles['SavingsCalculator__SliderButton']
                            }
                            onFocus={() => setShowInputField(true)}
                            onClick={() => setShowInputField(true)}>
                            {formatNumber(value, '')}
                            <span
                                className={styles['SavingsCalculator__Suffix']}>
                                {suffix}
                            </span>
                        </button>
                    )}
                </div>
            </div>
            <label className={'sr-only'} htmlFor={rangeId}>
                {title}
            </label>
            <input
                id={rangeId}
                className={styles['SavingsCalculator__InputRange']}
                style={{
                    backgroundSize: backgroundSize,
                }}
                onInput={onInput}
                value={value}
                type={'range'}
                min={minValue}
                max={maxValue}
                step={step}
            />
        </div>
    );
};

const Link = ({ href = '', title = '', target = '' }) => {
    return (
        <div className={styles['SavingsCalculator__ButtonContainer']}>
            <a
                href={href}
                className={styles['SavingsCalculator__Button']}
                target={target}>
                <span>{title}</span>
                <Icon
                    icon={'arrowRight'}
                    dimensions={{
                        width: '14px',
                        height: '14px',
                    }}
                />
            </a>
        </div>
    );
};

SavingsCalculator.propTypes = {};

export default SavingsCalculator;
