import { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

import styles from './LineChart.module.scss';

const LineChart = ({
    startAmount,
    monthlySaving,
    yearsOfSaving,
    interestRate,
    series,
}) => {
    useEffect(() => {
        const chartConfig = {
            backgroundColor: '#fcfcfc',
            title: {
                text: '',
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
                visible: false,
            },

            yAxis: {
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 1000',
                },
                title: {
                    text: '',
                },
                labels: {
                    enabled: false,
                },
            },

            tooltip: {
                crosshairs: false,
                shared: true,
                headerFormat: 'Om {point.x} år<br />',
                style: {
                    padding: '100px',
                    fontSize: '12px',
                    marginBottom: '20px',
                    lineHeight: '28px',
                },
                valueSuffix: ' kr',
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            series: series.map((item) => ({
                ...item,
                pointStart: 1,
                marker: {
                    radius: 0.5,
                },
                lineWidth: 1.5,
            })),
            subtitle: {
                text: `Om ${yearsOfSaving} år`,
                verticalAlign: 'top',
                align: 'right',
            },
        };

        chartConfig.colors = series.map((item) => item.color);

        Highcharts.setOptions({
            chart: {
                style: {
                    fontFamily: 'InterTight Regular',
                    fontSize: '16px',
                },
            },
        });

        chart.current = new Highcharts.Chart(
            chartContainerRef.current,
            chartConfig
        );

        return () => {
            chart.current.destroy();
        };
    }, [yearsOfSaving, startAmount, monthlySaving, interestRate, series]);

    const chart = useRef(null);
    const chartContainerRef = useRef(null);

    return <figure className={styles['LineChart']} ref={chartContainerRef} />;
};

LineChart.propTypes = {};

export default LineChart;
